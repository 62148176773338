import axios from 'axios';
import React, { useState } from 'react'
import Swal from 'sweetalert2';

function ShowStockClose() {
    const [manualdate,setManualdate]=useState('');
    const [tilldate,setTillDate]=useState('');
    const [results,setresults]=useState([]);
    const [isloading,setisloading]=useState(false);
    const handleManualdate=(e)=>{
        e.preventDefault();
        setManualdate(e.target.value);
        
        }
        const handleTillDate=(e)=>{
            e.preventDefault();
            setTillDate(e.target.value);
            
            }
            const handleSubmit=()=>{


                setisloading(true);


                if(manualdate===tilldate||manualdate>tilldate){
                  Swal.fire({
                      position: 'top-end',
                      icon: 'warning',
                      title: "closing date should be less than selling date",
                      showConfirmButton: false,
                      timer: 1500
                    })
                    setisloading(false);
              
                  return false;
              }

const data={
'manual_date':manualdate,
'tillDate':tilldate

}

axios.get('/sanctum/csrf-cookie').then(res=>{
    axios.post('api/showstockdayclose',data).then(res=>{
   //console.log(res.data.results);
    if(res.data.results.length>0){
        setresults(res.data.results);

    }else{
  Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: "No Closing Stock Saved!",
              showConfirmButton: false,
              timer: 1500
            })
    }
    setisloading(false);
 
    })
    
    })


            }
        

  return (
    <div className='row mt-2'>
       
      <div className='col-md-5 col-sm-5'>
      
        <h5 style={{color:'green'}} className='mb-2'>Closing Stock Date</h5>

  
<input type="date" className='form-control'  value={manualdate} name='manualdate' onChange={handleManualdate}></input>
</div>
<div className='col-md-5 col-sm-5'>
<h5 style={{color:'green'}} className='mb-2'>Last Sale Date</h5>

<input type="date" className='form-control'  value={tilldate} name='tilldate' onChange={handleTillDate}></input>
</div>
<div className='col-md-2 col-sm-2 mt-4'>
    
<button className='btn btn-primary' onClick={handleSubmit}  disabled={isloading} >
Fetch Record
</button>
</div>

<div className='col-12 mt-4'>
<table>
    <thead>
        <tr>
        <th>Sr</th>
        <th>Name</th>
        <th>Closing Qty</th>
        <th>Qty Available</th>
        <th>Qty Sold</th>
        <th>Status</th>
        </tr>
    </thead>

    <tbody>

    {results.map((row, index) => (
          <tr key={index}  style={{
            backgroundColor: row.result === 0 ? 'green' : 'red',
            color: 'white', // To ensure text is visible
          }}>
            <td>{row.sr}</td>
            <td>{row.name}</td>
            <td>{row.qty}</td>
            <td>{row.current_qty}</td>
            <td>{row.sold_qty}</td>
            
            <td>{row.result}</td>
          </tr>
        ))}
    </tbody>
</table>
</div>
    </div>
  )
}

export default ShowStockClose

