
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import axios from 'axios';
import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import {FaEdit, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import { useStateContext } from '../Context/ContextProvider';

function AssignSector() {
    const{type}= useStateContext();
  const [isThisClick,setIsThisClick]=useState('');

    const [sector,setSector]=useState([]);
    const [defaultsector,setDefaultSector]=useState(0);
    const [subsector,setSubSector]=useState([]);
    const [defaultsubsector,setDefaultSubSector]=useState(0);
    const [customer,setCustomer]=useState([]);
    const [defaultCustomer,setDefaultCustomer]=useState(0);
    const [selectedOption,setSelectedOption]=useState(null);

    const [isClick,setIsClick]=useState(false);
    const [currentPage, setCurrentPage] = useState(1);
 
    const [itemsPerPage, setItemsPerPage] = useState('');
    const [serialStart, setSerialStart] = useState('');
    
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery,setSearchQuery]=useState('');
    const [users,setUsers]=useState([]);
    
    useEffect(() => {
      setSerialStart((currentPage - 1) * itemsPerPage + 1);
    }, [currentPage, itemsPerPage]);
    
    const handleSearchChange = (event) => {

        setSearchQuery(event.target.value);
        setCurrentPage(1);
      };

      useEffect(()=>{
 
        axios.get(`/api/view/assign/user/shop?page=${currentPage}&search=${searchQuery}`).then(response=>{
         
         setItemsPerPage(response.data.per_page);
            setUsers(response.data.data);
          setTotalPages(response.data.last_page);
        })
       
        
      
      },[currentPage,searchQuery,isThisClick]);
      
     const handleEdit=(e,id,index)=>{
      e.preventDefault();
      setIsThisClick(index);
axios.post(`api/edit/assign/shop/${id}`).then(res=>{

  setDefaultSector(res.data.sector_id);
setDefaultSubSector(res.data.subsector_id);
setCustomer([...customer,res.data.shop]);
setSelectedOption(res.data.shop);
setDefaultCustomer(res.data.user_id);
setIsThisClick('');
})

     } 
      const handleDelete=(e,id,index)=>{
        e.preventDefault();
setIsThisClick(index);


axios.delete(`api/delete/assign/shop/${id}`).then(res=>{

    if(res.status===200){

        toast.error('Successfuly Deleted!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }
setIsThisClick('');
})




      }
const handleAssign=()=>{
    setIsClick(true);
    const data={
        id:defaultCustomer,
        sectorid:defaultsector,
        subsectorid:defaultsubsector
        
        }
   
axios.post('/api/assign/user/shop',data).then(res=>{
setIsClick(false);
if(res.data.status===200){
    toast.success('Successfuly Added!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
}else{
    toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
}

})



}

    const handleSubmit=(val)=>{
setDefaultCustomer(val.id);

    }
    useEffect(()=>{
        axios.get('/sanctum/csrf-cookie').then(response => {
     
      axios.get(`/api/getsectors`).then(res=>{
        setSector(res.data.sectors);
        setDefaultSector(res.data.sectors[0]['id']);
      
      });
      axios.get(`/api/getcustomers`).then(res=>{
        setCustomer(res.data.customers);
      
      
      });
     
      
        }); },[]);
        useEffect(() => {
            if (defaultsector) {
              
              axios.get(`/api/getsubsectors/${defaultsector}`)
              .then(response => {
                setSubSector(response.data.subsectors);
                setDefaultSubSector(response.data.subsectors[0]['id']);
              })
              .catch(error => {
                console.error(error);
              });
            }
           
          },[defaultsector]);
    
  return (
    <div className='row fadeInDown animated'>
    <center>
     <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

     <div className='co-md-11'>
<h2>Assign Region To Customer  </h2> <hr/>
         </div>   
  
<Row className='g-2'>
<div className='col-md mt-4'>

 <FloatingLabel  label="Region Name">

   <Form.Select aria-label="Sector Name" value={defaultsector} onChange={(e)=>setDefaultSector(e.target.value)}  name='sector' >
    
   {sector && sector.map(item => (
     
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>


<div className='col-md mt-4'>
 <FloatingLabel  label="City Name">

   <Form.Select aria-label="Sector Name" value={defaultsubsector}  onChange={(e)=>setDefaultSubSector(e.target.value)}  name='sector' >
   <option>Select City</option>
   {subsector && subsector.map(item => (
     
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>

</Row>
<Row className='g-2'>


<div className='col-md mt-4'>
 
<Autocomplete
  disablePortal
  id="combo-box-demo"
  value={selectedOption}
  onChange={(event, newValue) => {
    
    setSelectedOption(newValue);
    handleSubmit(newValue);
  }}
  getOptionLabel={(results) => `${results.name} `}
  isOptionEqualToValue={(option, value) =>
    (option && option.name) === (value && value.name) 
  
  }
  options={customer}
  noOptionsText={"No Item Found"}
  renderOption={(props, results) => (
    <Box component="li" {...props} key={results.id}>
      {results.name} 
    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label={selectedOption ? <Puff stroke="red" /> : 'Search Item'}
      variant="outlined"
      className="form-control"
     
      onKeyDown={(event) => {
        if (event.key === 'Enter' && params.inputProps.value) {
          // Check if params.inputProps.value is not null or undefined
          const inputText = params.inputProps.value.toLowerCase();
      
          // If Enter is pressed and there's text in the input, select the first matching result
          const matchingOptions = customer.filter((option) =>
            (option.name || '').toLowerCase().indexOf(inputText) !== -1 
        
          );
      
          if (matchingOptions.length > 0) {
            setSelectedOption(matchingOptions[0]);
            handleSubmit(matchingOptions[0]);
          }
        }
      }}
    />
  )}
/>
 </div>







</Row>
 <div className='col-md-10 mt-4'>

 <Row className='g-2 mt-4'>


<div className='col-md-11'>
<button  onClick={(e)=>handleAssign(e)} className='btn btn-info btn-lg btn-block'>{isClick?  <Puff width={25} height={25} stroke="red"/>:'Save'}  </button>

</div>

 </Row></div>
 </div></center>


 <Row className='g-1'>

<div className='col-md' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>

<Row className='g-2'>
  <div className='col-md-4 mb-1'>
  <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

  </div>

 



  
    
</Row>

    
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Region Name</th>
        <th>City Name</th>
        <th>User Name</th>
      
       
        <th>Delete</th>
        <th>Edit</th>
         
        </tr>
    </thead>
  <tbody>
 {users && users.map((item,index)=>(


 <tr key={item.id}  >
 

    <td>{serialStart+index}</td>
    <td >{item.sector_name}</td>
    <td >{item.subsector_name}</td>
    <td >{item.user_name}</td>
   
   
  
    <td><button className='btn btn-danger' disabled={type!=='Managment'?true:false} onClick={(e) => handleDelete(e,item.id,index)} style={{marginRight:'3px'}}>{isThisClick===index?<Puff height={10} width={15}/> :<FaTrash style={{marginBottom:'3px'}}/>}</button> </td>
    <td><button className='btn btn-warning' disabled={type!=='Admin'?true:false} onClick={(e) => handleEdit(e,item.id,index)} style={{marginRight:'3px'}}>{isThisClick===index?<Puff height={10} width={15}/> :<FaEdit style={{marginBottom:'3px'}}/>}</button> </td>
        
       
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div>

</Row>
 </div>
  )
}

export default AssignSector
