import React, {  useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Row, Table } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import axios from 'axios';
import { useStateContext } from '../Context/ContextProvider';
function BatchListInvoices() {
  const [isClick,setIsClick]=useState(false);
  const [isClickPrint,setIsClickPrint]=useState(false);
    const [defaultsalesman,setDefaultSalesman]=useState('');
    const [defaultsector,setDefaultSector]=useState('');
    const [salesman,setSalesman]=useState([]);
    const [sector,setSector]=useState([]);
    const [manualdate,setManualDate]=useState('');
    const [manualdateto,setManualDateTo]=useState('');
    const {type,userId}=useStateContext();
    const [invoiceList,setInvioceList]=useState([]);
    const [invoiceNumbersRange, setInvoiceNumbersRange] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
   
    useEffect(() => {
      if(invoiceList.length>0){

      
      if(invoiceNumbersRange.length===invoiceList.length){
        setIsAllChecked(true);
       
      }else{
       

        setIsAllChecked(false);
      }}
    }, [invoiceNumbersRange,invoiceList]);
    const handleCheckAllChange = (e) => {
      if (e.target.checked) {
        // If "Check All" is checked, select all invoice IDs
        setInvoiceNumbersRange(invoiceList.map((invoice) => invoice.visitor_id));
      } else {
        // If "Check All" is unchecked, deselect all invoice IDs
        setInvoiceNumbersRange([]);
      }
      //setIsAllChecked(e.target.checked);
    };
    const handleCheckboxChange = (e, id) => {
      if (e.target.checked) {
        // Add id to the state array when checked
        setInvoiceNumbersRange((prev) => [...prev, id]);
      } else {
        // Remove id from the state array when unchecked
        setInvoiceNumbersRange((prev) => prev.filter((item) => item !== id));
      }
    };
useEffect(()=>{
axios.get('/api/getsectors').then(res=>{

setSector(res.data.sectors);
setDefaultSector(res.data.sectors[0]['id']);

})


},[])
useEffect(()=>{
    axios.get('/api/getsalesman').then(res=>{
    
    setSalesman(res.data.salesman);
    setDefaultSalesman(res.data.salesman[0]['id']);
    
    })
    
    
    },[])

    const handleFetchList=()=>{
      setIsClick(true);
      if(manualdate===''||manualdateto===''){
        alert('Date Must Be Selected');
        setIsClick(false);
        return false;
      }
      const data={
      'salesman':type==='Admin'?defaultsalesman:userId,
      'sector':defaultsector,
      'date':manualdate,
      'dateto':manualdateto
      
      
      }
      
      
      axios({
          url: 'api/get/batch/invoices',
          method: 'POST',
          
          data:data
        }).then((response) => {
          
        setInvioceList(response.data);
         setIsClick(false);
        });
      

    }
const handleFetchReport=()=>{
    
setIsClickPrint(true);
if(invoiceNumbersRange.length<1){
  alert('Select Invoices First');
  setIsClickPrint(false);
  return false;
}
const data={
'salesman':type==='Admin'?defaultsalesman:userId,
'sector':defaultsector,
'date':manualdate,
'dateto':manualdateto,
'visitorids':invoiceNumbersRange,


}


axios({
    url: 'api/post/batch/invoices',
    method: 'POST',
    responseType: 'blob', 
    data:data
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
    
   setIsClickPrint(false);
  });


}
  return (
    <div className='fadeInDown animated p-20' style={{display:'flex',justifyContent:'space-between'}}>
 
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',textAlign:'center',padding:10}}>

        <div className='co-md-11'>
<h2>BATCH INVOICES</h2> <hr/>
            </div>   
       
    <div className='col-md-12 mt-4' >
    <FloatingLabel label="Enter Date From">
        <Form.Control type="date" value={manualdate} onChange={(e)=>setManualDate(e.target.value)} name="manualdate" placeholder="Enter Manual Date"  />
      </FloatingLabel>
     
    </div>
    <div className='col-md-12 mt-4'>
    <FloatingLabel  label="Enter Date To">
        <Form.Control type="date" value={manualdateto} onChange={(e)=>setManualDateTo(e.target.value)} name="manualdate" placeholder="Enter Manual Date"  />
      </FloatingLabel>
     
    </div>

    <div className='col-md-12 mt-4'>
    <FloatingLabel  label="Select Region Name ">
   
      <Form.Select aria-label="Sector Name"  value={defaultsector}  name='sector_id' onChange={(e)=>setDefaultSector(e.target.value)}>
      {sector && sector.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>

    <div className='col-md-12 mt-4'>
      
    <FloatingLabel  label="Sales Associate ">
   
      <Form.Select aria-label="Salesman Name" value={defaultsalesman}   name='salesman_id' onChange={(e)=>setDefaultSalesman(e.target.value)}>
      {salesman && salesman.map(item => (
     
        <option key={item.id} value={item.id}>
        {item.name} </option>
     
  
    
    
    ))}
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md-12 mt-4'>

    <Row className='g-2 mt-4'>

<div className='col md'>
<button onClick={handleFetchList} className='btn btn-warning btn-lg  btn-block'>{isClick?  <Puff width={30} height={26} stroke='red'/>:'Fetch List'}  </button>

</div>

    </Row></div>
    </div>
    <div className='col-md-6 mt-3 table-responsive' style={{boxShadow:'0px 0px 5px 0px #9E9E9E'}}>

<Table className='table'>
<thead>
  <tr>
  <th>Sr</th>
  <th>Name</th>
  <th>Salesman </th>
  <th>Sector</th>
  <th>SubSector </th>
  <th>Invoice</th>
  <th>
    <input type='checkbox' checked={isAllChecked} onChange={(e)=>handleCheckAllChange(e)}/>
    All</th>
  </tr>
</thead>
<tbody>
  {invoiceList&& invoiceList.map((item,index)=>(
 <tr key={index}>
 <td>{item.Sr}</td>
 <td>{item.customerName}</td>
 <td>{item.salesmanName}</td>
 <td>{item.sectorName}</td>
 <td>{item.subsectorName}</td>
 <td>{item.visitor_id}</td>
 <td>
<input type='checkbox'  checked={invoiceNumbersRange.includes(item.visitor_id)} value={item.visitor_id} onChange={(e)=>handleCheckboxChange(e,item.visitor_id)}/>

 </td>

 </tr>

  ))}
 
</tbody>
</Table>

<button onClick={handleFetchReport} className='btn btn-success btn-lg  btn-block'>{isClickPrint?  <Puff width={30} height={26} stroke='red'/>:'Print Report'}  </button>
    
    </div>
    
 
    </div>
  )
}

export default BatchListInvoices
